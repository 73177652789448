@charset "UTF-8";
/*
    0 - 600px Phone
    600px - 900px Tablet portrait
    900px - 1200px Tablet landscape
    [1200-1800] is where our normal styles apply
    1080px + Big Screen

breakpoint argument choices:

phone
tap-port
tab-land
big-desktop

1em =16px

*/
.row:after {
  display: block;
  clear: both;
  content: ''; }

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  font-size: 72.5%; }
  @media (max-width: 75em) {
    html {
      font-size: 68%; } }
  @media (max-width: 56.25em) {
    html {
      font-size: 61%; } }
  @media (max-width: 26.25em) {
    html {
      font-size: 57%; } }
  @media (min-width: 112.5em) {
    html {
      font-size: 75%; } }

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #F0F0F0;
  cursor: default; }

a {
  cursor: pointer; }

p {
  color: #F0F0F0; }

body {
  box-sizing: border-box;
  width: 100%;
  background-color: #000;
  overflow-x: hidden;
  font-family: "Cinzel", serif;
  position: relative; }
  @media (max-width: 75em) {
    body {
      padding: 2rem; } }

.wrapper {
  margin: 0 auto;
  width: 100%;
  background-image: url(../images/top-background.gif);
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  content: ""; }

button {
  cursor: pointer; }

img {
  width: 100%; }

p {
  cursor: default; }

input:focus {
  box-shadow: none;
  outline: none; }

::-webkit-scrollbar {
  background-color: #F0F0F0;
  width: 0.5rem; }

::-webkit-scrollbar-thumb {
  background-color: #dfbd5e; }

ul li {
  list-style-type: none; }

#upper-section {
  /*min-height: 60vh;*/ }

.button {
  display: flex;
  justify-content: flex-end;
  margin-top: -1.5rem;
  position: relative; }
  .button__small {
    background-color: #000;
    border: 1px solid #dfbd5e;
    border-image-source: linear-gradient(#dfbd5e, #907140);
    border-image-slice: 14;
    color: #dfbd5e;
    font-weight: bold;
    font-family: "Cinzel", sans-serif;
    box-sizing: border-box;
    position: absolute;
    padding: 0.5rem 1.5rem; }
    @media (max-width: 75em) {
      .button__small {
        margin-right: -1rem;
        padding: 0.5rem; } }
    @media (max-width: 56.25em) {
      .button__small {
        margin-right: 1rem; } }
  .button__small:before, .button__small:after {
    box-sizing: inherit;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%; }

.btn {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 5rem;
  position: relative; }
  .btn__large {
    background-color: #000;
    border: 1px solid #dfbd5e;
    border-image-source: linear-gradient(#dfbd5e, #907140);
    border-image-slice: 14;
    color: #dfbd5e;
    font-weight: bold;
    font-family: "Cinzel", sans-serif;
    box-sizing: border-box;
    position: absolute;
    padding: 1rem 4rem;
    bottom: 0.5rem; }
  .btn__large:after, .btn__large:before {
    box-sizing: inherit;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%; }

.button__gold {
  background-color: #000;
  border: 1px solid #dfbd5e;
  border-image-source: linear-gradient(#dfbd5e, #907140);
  border-image-slice: 14;
  color: #dfbd5e;
  font-weight: bold;
  font-family: "Cinzel", sans-serif;
  box-sizing: border-box;
  position: absolute;
  background-color: #dfbd5e;
  color: #2c313e;
  padding: 1rem 4rem;
  bottom: 0.5rem; }

.button__gold:active,
.button__gold:hover {
  background-color: #000;
  color: #dfbd5e; }

.group {
  display: flex;
  margin-top: 2rem;
  position: relative; }
  .group__btn:not(:first-child) {
    margin-left: 38rem; }
    @media (max-width: 75em) {
      .group__btn:not(:first-child) {
        margin-left: 33rem; } }
    @media (max-width: 56.25em) {
      .group__btn:not(:first-child) {
        margin-left: 28rem; } }
    @media (max-width: 37.5em) {
      .group__btn:not(:first-child) {
        margin-left: 15rem; } }
  .group__btn-larger {
    background-color: #000;
    border: 1px solid #dfbd5e;
    border-image-source: linear-gradient(#dfbd5e, #907140);
    border-image-slice: 14;
    color: #dfbd5e;
    font-weight: bold;
    font-family: "Cinzel", sans-serif;
    box-sizing: border-box;
    position: absolute;
    padding: 1rem 8rem;
    text-align: center; }
    @media (max-width: 75em) {
      .group__btn-larger {
        padding: 1rem 2.5rem; } }
    @media (max-width: 56.25em) {
      .group__btn-larger {
        padding: 1rem 1.5rem; } }
    @media (max-width: 37.5em) {
      .group__btn-larger {
        padding: 1rem 0.5rem; } }
  .group__btn-larger:after, .group__btn-larger:before {
    box-sizing: inherit;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%; }

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }

.disabled {
  border: 1px solid #4d4d4a;
  color: #4d4d4a; }

.faq-position {
  top: 3.5rem; }

.white {
  color: #F0F0F0; }

.almost-full-width {
  width: 60%; }

.block {
  padding: 0.7rem 7.1rem; }

.full-width {
  width: 100%; }

.medium-block {
  padding: 0.7rem 10.6rem; }
  @media (max-width: 75em) {
    .medium-block {
      padding: 0.7rem 6.6rem; } }
  @media (max-width: 56.25em) {
    .medium-block {
      padding: 1rem 1.6rem; } }

@media (max-width: 37.5em) {
  .responsive__margin {
    margin-left: 1.9rem; } }

.large-block {
  padding: 0.7rem 16.2rem; }
  @media (max-width: 75em) {
    .large-block {
      padding: 0.7rem 12.2rem; } }
  @media (max-width: 56.25em) {
    .large-block {
      padding: 0.7rem 8.2rem; } }

.width {
  width: 11rem;
  left: -5rem; }
  @media (max-width: 75em) {
    .width {
      top: -1rem;
      width: 6rem;
      left: -2.8rem; } }
  @media (max-width: 56.25em) {
    .width {
      width: 100%;
      left: 0rem;
      top: 0.8rem; } }

.inline-text {
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.2rem; }

.category-button {
  margin-top: -0.5rem;
  margin-left: 0.5rem; }

.profile-button {
  margin-left: -20rem; }
  @media (min-width: 112.5em) {
    .profile-button {
      margin-left: -33rem; } }
  @media (max-width: 56.25em) {
    .profile-button {
      margin-left: -11rem; } }
  @media (max-width: 37.5em) {
    .profile-button {
      margin-left: -22rem;
      margin-bottom: 1rem; } }

.mule-buttons {
  margin: 7rem 0 4rem 0; }

.absolute {
  bottom: 10.5rem; }
  @media (max-width: 26.25em) {
    .absolute {
      width: 100%; } }

.position {
  bottom: 1.5rem;
  left: 1rem; }
  @media (min-width: 112.5em) {
    .position {
      bottom: -9.5rem;
      left: 0; } }
  @media (max-width: 75em) {
    .position {
      bottom: -11.5rem;
      left: 0; } }
  @media (max-width: 56.25em) {
    .position {
      bottom: -13.5rem;
      left: 0.5rem; } }
  @media (max-width: 37.5em) {
    .position {
      left: 0;
      bottom: -20.5rem; } }

.margin {
  margin-left: 2rem; }

.button-position {
  top: 1.5rem;
  right: 0.7rem; }
  @media (min-width: 112.5em) {
    .button-position {
      width: 95%;
      padding: 0.7rem 6.6rem; } }
  @media (max-width: 75em) {
    .button-position {
      right: 1.7rem; } }
  @media (max-width: 56.25em) {
    .button-position {
      right: -0.3rem;
      width: 63%; } }
  @media (max-width: 37.5em) {
    .button-position {
      width: 97%;
      right: -0.2rem;
      top: 1.7rem; } }
  @media (max-width: 26.25em) {
    .button-position {
      right: -0.4rem; } }

.button-margin {
  margin-top: -2.4rem; }
  @media (max-width: 56.25em) {
    .button-margin {
      margin-top: 0.6rem;
      margin-right: -1rem; } }
  @media (max-width: 37.5em) {
    .button-margin {
      width: 100%;
      margin-right: 0;
      margin-top: -2.4rem; } }

.no-flex {
  display: flex;
  justify-content: baseline;
  position: relative; }

.flex-left {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.5rem; }
  @media (max-width: 37.5em) {
    .flex-left {
      justify-content: center; } }

.order__button {
  margin-top: 2rem !important; }

.draw {
  transition: color 0.25s; }
  .draw:before, .draw:after {
    border: 1px solid transparent;
    width: 0;
    height: 0; }
  .draw:before {
    top: 0;
    left: 0; }
  .draw:after {
    bottom: 0;
    right: 0; }
  .draw:hover {
    color: #dfbd5e; }
  .draw:hover:before, .draw:hover:after {
    width: 100%;
    height: 100%; }
  .draw:hover:before {
    border-top-color: #dfbd5e;
    border-right-color: #dfbd5e;
    transition: width 0.2s ease-out, height 0.2s ease-out 0.2s; }
  .draw:hover:after {
    border-bottom-color: #dfbd5e;
    border-left-color: #dfbd5e;
    transition: border-color 0s ease-out 0.2s, width 0.2s ease-out 0.2s, height 0.2s ease-out 0.5s; }

.contact__wrapper {
  width: 70%;
  margin: 0 auto;
  text-align: center; }

.contact__wrapper button:disabled {
  cursor: default;
  opacity: 0.5; }

.contact__main-title {
  text-align: center;
  text-transform: uppercase;
  color: #F0F0F0;
  font-size: 2rem;
  margin-bottom: 3rem;
  overflow: hidden;
  letter-spacing: 0.5rem;
  font-weight: 100; }

.contact__paragraphs-first:before {
  content: "";
  background-image: -webkit-linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
  background-image: linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
  display: inline-block;
  height: 0.1rem;
  width: 75%;
  width: 100%; }

.contact__paragraphs:after, .contact__paragraphs:before {
  content: "";
  background-image: -webkit-linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
  background-image: linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
  display: inline-block;
  height: 0.1rem;
  width: 75%;
  width: 100%; }

.contact__paragraphs:after:nth-child(1) {
  display: none; }

.contact__form-title {
  color: #F0F0F0;
  font-family: "Open-Sans", sans-serif;
  margin-left: 1rem; }
  @media (max-width: 37.5em) {
    .contact__form-title {
      margin-left: 0.5rem; } }

.contact__input, .contact__choice {
  background-color: #2c313e;
  color: #b1b1b2;
  border: 1px solid #d1bf8b;
  margin: 0.7rem;
  width: 225px;
  height: 37px;
  padding: 0.5rem;
  width: 100%; }

.contact__choice {
  margin-top: 2rem; }

.contact__message {
  background-color: #2c313e;
  color: #b1b1b2;
  border: 1px solid #d1bf8b;
  margin: 0.7rem;
  width: 225px;
  height: 37px;
  padding: 0.5rem;
  resize: none;
  height: 300px;
  width: 100%; }

.contact__password-title {
  text-align: center; }

.contact__password-title span a {
  color: #dfbd5e; }

.contact__input-tracking {
  background-color: #2c313e;
  color: #b1b1b2;
  border: 1px solid #d1bf8b;
  margin: 0.7rem;
  width: 225px;
  height: 37px;
  padding: 0.5rem;
  width: 50%; }
  @media (max-width: 37.5em) {
    .contact__input-tracking {
      width: 100%; } }

.contact__input-sign-in {
  background-color: #2c313e;
  color: #b1b1b2;
  border: 1px solid #d1bf8b;
  margin: 0.7rem;
  width: 225px;
  height: 37px;
  padding: 0.5rem;
  width: 50%; }
  @media (max-width: 37.5em) {
    .contact__input-sign-in {
      width: 100%; } }

.contact__labels-login {
  text-align: left; }

.contact__input-login {
  background-color: #2c313e;
  color: #b1b1b2;
  border: 1px solid #d1bf8b;
  margin: 0.7rem;
  width: 225px;
  height: 37px;
  padding: 0.5rem;
  width: 100%;
  margin: 0.7rem 0.7rem 0.7rem 0; }

.contact__input-register {
  background-color: #2c313e;
  color: #b1b1b2;
  border: 1px solid #d1bf8b;
  margin: 0.7rem;
  width: 225px;
  height: 37px;
  padding: 0.5rem;
  width: 50%; }
  @media (max-width: 37.5em) {
    .contact__input-register {
      width: 100%; } }

.contact__labels {
  text-align: left;
  margin-left: 17rem; }
  @media (max-width: 75em) {
    .contact__labels {
      margin-left: 13rem; } }
  @media (max-width: 37.5em) {
    .contact__labels {
      margin-left: 0;
      text-align: left; } }

.contact__paragraph {
  text-align: center;
  font-family: "Open-Sans", sans-serif;
  font-weight: bold;
  margin: 5rem 0;
  font-size: 1.2rem; }

.contact__sub-paragraph {
  text-align: center;
  font-family: "Open-Sana", sans-serif;
  font-weight: 100;
  margin-bottom: 5rem;
  font-size: 1.1rem; }

.contact__paragraph:before {
  height: 0.2rem;
  position: relative;
  vertical-align: middle;
  width: 20%;
  background: #dfbd5e;
  background: linear-gradient(to left, #dfbd5e, transparent); }

.contact__sub-paragraph:after {
  height: 0.2rem;
  position: relative;
  vertical-align: middle;
  width: 20%;
  background: #dfbd5e;
  background: linear-gradient(to left, #dfbd5e, transparent); }

.large-search {
  width: 100%;
  background-color: #111318;
  border: 1px solid #907140;
  height: 5rem;
  position: relative;
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.5); }
  .large-search.open {
    z-index: 7001; }
  .large-search__wrapper {
    position: relative; }
  .large-search__text {
    width: 98%;
    height: auto;
    padding: 1rem;
    margin-left: 0.7rem;
    margin-top: 0.6rem;
    background-color: #2c313e;
    color: #b1b1b2;
    border: 1px solid #dfbd5e; }
    @media (max-width: 75em) {
      .large-search__text {
        width: 97.7%; } }
    @media (max-width: 56.25em) {
      .large-search__text {
        margin-top: 0.4rem; } }
  .large-search__button {
    background-color: #000;
    border: 1px solid #dfbd5e;
    border-image-source: linear-gradient(#dfbd5e, #907140);
    border-image-slice: 14;
    color: #dfbd5e;
    font-weight: bold;
    font-family: "Cinzel", sans-serif;
    box-sizing: border-box;
    position: absolute;
    padding: 0.7rem 1.7rem;
    text-transform: uppercase;
    right: 12px;
    bottom: 2px; }
    @media (max-width: 37.5em) {
      .large-search__button {
        right: 6px; } }
  .large-search__button:disabled {
    opacity: 0.5;
    cursor: default; }
  .large-search__suggestions {
    margin-top: 1rem; }
  .large-search__suggestions ul {
    position: relative;
    z-index: 99;
    background-color: #2c313e;
    border: 1px solid #907140;
    max-height: 300px;
    overflow-y: scroll;
    padding: 1rem; }
  .large-search__suggestions li {
    cursor: pointer;
    padding: 0.5rem;
    color: #fff; }
  .large-search__suggestions li:hover {
    opacity: 0.5; }

.sidebar {
  background-color: #111318;
  width: 100%;
  height: auto;
  border: 1px solid #907140;
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.5); }
  @media (max-width: 37.5em) {
    .sidebar {
      height: auto; } }
  .sidebar__choice {
    background-color: #2c313e;
    color: #b1b1b2;
    border: 1px solid #d1bf8b;
    margin: 0.7rem;
    width: 225px;
    height: 37px;
    padding: 0.5rem;
    width: 93%; }
    @media (max-width: 56.25em) {
      .sidebar__choice {
        width: 90%; } }
    @media (max-width: 37.5em) {
      .sidebar__choice {
        width: 97%;
        height: auto; } }
  .sidebar__title {
    margin: 1.1rem 2rem;
    font-size: 1.6rem; }
  .sidebar__categories-wrap {
    margin-bottom: 1.5rem; }
  .sidebar__categories {
    display: flex;
    flex-direction: column;
    list-style: none;
    font-size: 1.3rem;
    position: relative; }
  .sidebar__categories li a {
    color: #dfbd5e;
    padding-left: 3rem;
    text-decoration: none;
    display: flex;
    font-size: 1.3rem;
    padding: 0.3rem 3rem; }
  .sidebar__categories > li > a:active,
  .sidebar__categories > li > a:hover {
    background-color: #32281f; }
  .sidebar__sub--items {
    background-color: #32281f;
    position: absolute;
    margin-left: -100px;
    top: 91px;
    visibility: hidden;
    opacity: 0;
    transition: transform 0.2s;
    transform: translateX(50px);
    list-style: none;
    height: auto;
    width: 201px;
    z-index: 5; }
    @media (max-width: 75em) {
      .sidebar__sub--items {
        top: 83px;
        width: 182px; } }
    @media (max-width: 56.25em) {
      .sidebar__sub--items {
        top: 75px;
        width: 166px; } }
    @media (max-width: 37.5em) {
      .sidebar__sub--items {
        margin-left: -160px; } }
    @media (max-width: 26.25em) {
      .sidebar__sub--items {
        top: 70px;
        width: 160px; } }
  .sidebar__location-select-wrap {
    width: 95%;
    margin: 0 auto; }
  .sidebar__sub--items li {
    margin-left: -1.5rem;
    margin-bottom: 0.8rem;
    font-size: 1rem; }
  .sidebar__sub--items li:first-child {
    margin-top: 1rem; }
  .sidebar__categories li:hover > ul {
    left: 100%;
    visibility: visible;
    opacity: 1;
    transform: translate(0px); }
  .sidebar__categories li a:hover {
    color: #F0F0F0; }
  .sidebar__choice:focus {
    box-shadow: none;
    outline: none; }

.sidebar-short {
  background-color: #111318;
  width: 100%;
  height: auto;
  border: 1px solid #907140; }
  @media (min-width: 112.5em) {
    .sidebar-short {
      width: 80%; } }

.location-select {
  margin: 0.6rem auto;
  text-align: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #F0F0F0;
  position: relative; }
  .location-select.open {
    z-index: 6001; }
  .location-select__wrap {
    background: #222939; }
  .location-select.open .location-select__wrap {
    border: 1px solid #907140;
    box-shadow: 0px 0px 20px 20px rgba(0, 0, 0, 0.8); }
  .location-select__globe {
    width: 20px;
    height: 20px;
    fill: #dfbd5e;
    position: absolute;
    left: 0.9rem;
    opacity: 0.7;
    top: 0.9rem; }
  .location-select__caret {
    width: 20px;
    height: 20px;
    fill: #dfbd5e;
    -moz-transform: scaleY(-1);
    -o-transform: scaleY(-1);
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
    filter: FlipY;
    -ms-filter: "FlipY";
    position: absolute;
    right: 0.7em;
    top: 0.5rem; }
  .location-select.open .location-select__caret {
    -moz-transform: scaleY(1);
    -o-transform: scaleY(1);
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    top: 1.2rem; }
  .location-select__head {
    font-size: 1.2em;
    cursor: pointer;
    background: #2c313e;
    position: relative;
    /*padding: 1rem 0;*/
    color: #dfbd5e;
    /*margin-top: 0.8rem;*/
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    vertical-align: middle;
    border: 1px solid #907140; }
  .location-select.open .location-select__head {
    border: 0; }
  .location-select__location {
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    padding: 1rem 0; }
  .location-select__selection {
    cursor: default;
    position: relative;
    display: none;
    background: #222939;
    padding: 1rem 0; }
  .location-select__selection li {
    display: inline-block;
    padding: 0.5em 0;
    /*padding-bottom: 0.5em;*/
    font-size: 1.1em;
    color: #F0F0F0;
    /*width: 25%;*/
    vertical-align: middle;
    list-style: none;
    color: #dfbd5e; }
  .location-select__realm {
    width: 30%; }
  .location-select__mode {
    width: 25%; }
  .location-select__core {
    width: 20%; }
  .location-select__pack {
    width: 25%; }
  .location-select__realm, .location-select__mode, .location-select__core, .location-select__pack {
    cursor: pointer;
    opacity: 1;
    font-style: normal; }
  .location-select__realm:hover, .location-select__mode:hover, .location-select__core:hover, .location-select__pack:hover {
    background-color: #dfbd5e;
    color: #222939; }
  .location-select__dropdown ul {
    background: #2c313e;
    padding-top: 1em;
    padding-bottom: 1em;
    display: none; }
  .location-select__dropdown ul li {
    cursor: pointer;
    padding: 0.5em 0em;
    font-size: 1.5em;
    color: #dfbd5e;
    opacity: 0.9;
    list-style: none; }
  .location-select__dropdown ul li:hover {
    color: #222939;
    background-color: #dfbd5e; }

#modalOverlay {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  z-index: 6000; }

.overlay {
  position: relative;
  cursor: pointer; }
  .overlay__wrapping {
    position: absolute;
    top: 0;
    bottom: 0px;
    left: 0;
    color: #F0F0F0;
    box-shadow: 0 0 0 1px #907140;
    background-image: linear-gradient(0deg, #13161e, transparent);
    width: 100%; }
  .overlay:hover .overlay__wrapping,
  .overlay:active .overlay _wrapping {
    /*box-shadow: 0 0 0 2.5px $color-primary;*/
    background-color: rgba(0, 0, 0, 0.5);
    transition: 0.3s; }
  .overlay__lens-content {
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 1rem;
    padding-right: 1rem;
    display: none; }
  .overlay__lens {
    fill: white;
    height: 20px;
    width: 20px; }
  .overlay__wrapping:hover .overlay__lens-content {
    /*display: block;*/ }
  .overlay__content-title {
    margin-top: 11rem;
    font-size: 1.5rem;
    margin-left: 1rem;
    font-weight: bold;
    cursor: pointer;
    position: relative;
    z-index: 2; }
    @media (max-width: 75em) {
      .overlay__content-title {
        margin-top: 12rem; } }
    @media (max-width: 56.25em) {
      .overlay__content-title {
        margin-top: 16.5rem;
        font-size: 1.3rem; } }
    @media (max-width: 37.5em) {
      .overlay__content-title {
        margin-top: 15.5rem;
        font-size: 1.5rem; } }
    @media (max-width: 26.25em) {
      .overlay__content-title {
        margin-top: 12.5rem; } }
  .overlay__content-title-category {
    margin-top: 13rem;
    font-size: 2rem;
    margin-left: 1rem;
    font-weight: bold;
    cursor: pointer; }
    @media (max-width: 75em) {
      .overlay__content-title-category {
        margin-top: 9rem; } }
    @media (max-width: 56.25em) {
      .overlay__content-title-category {
        margin-top: 6rem; } }
    @media (max-width: 37.5em) {
      .overlay__content-title-category {
        margin-top: 22rem; } }
    @media (max-width: 26.25em) {
      .overlay__content-title-category {
        margin-top: 16rem; } }
  .overlay__price {
    font-size: 1.7rem;
    color: #F0F0F0;
    position: relative;
    margin-left: 1rem;
    font-weight: bold;
    cursor: pointer;
    z-index: 2; }
  .overlay span {
    color: #4d4d4a;
    font-size: 1.3rem;
    text-decoration: line-through; }
  .overlay__content-title-large {
    font-size: 2rem;
    margin-top: 26.5rem;
    margin-left: 1rem;
    font-weight: bold;
    cursor: pointer; }
    @media (max-width: 75em) {
      .overlay__content-title-large {
        margin-top: 19.5rem; } }
    @media (max-width: 56.25em) {
      .overlay__content-title-large {
        margin-top: 12.5rem; } }
    @media (max-width: 37.5em) {
      .overlay__content-title-large {
        margin-top: 18.5rem; } }
    @media (max-width: 26.25em) {
      .overlay__content-title-large {
        margin-top: 13.5rem; } }
  .overlay__sub-title-large {
    font-size: 3rem;
    margin-left: 1rem;
    font-weight: bold;
    cursor: pointer; }
  .overlay__content-title-small {
    margin-top: 7.5rem;
    font-family: "Open-Sans", sans-serif;
    margin-left: 1rem;
    font-weight: bold;
    cursor: pointer; }
    @media (max-width: 75em) {
      .overlay__content-title-small {
        margin-top: 8rem; } }
    @media (max-width: 56.25em) {
      .overlay__content-title-small {
        margin-top: 10rem; } }
    @media (max-width: 37.5em) {
      .overlay__content-title-small {
        margin-top: 8rem;
        font-size: 1.2rem; } }
    @media (max-width: 26.25em) {
      .overlay__content-title-small {
        margin-top: 11rem; } }
  .overlay__content-title-extra-small {
    margin-top: 6rem;
    font-family: "Open-Sans", sans-serif;
    margin-left: 1rem;
    font-weight: bold;
    cursor: pointer; }
  .overlay__price-small {
    margin-top: 0.3rem;
    margin-left: 1rem;
    font-weight: bold;
    cursor: pointer;
    position: relative; }
    @media (max-width: 37.5em) {
      .overlay__price-small {
        font-size: 1.3rem; } }
  .overlay__stats-small {
    margin-left: 1rem;
    margin-top: 0.3rem;
    color: #d1bf8b;
    font-family: "Open-Sans", sans-serif; }
    @media (max-width: 37.5em) {
      .overlay__stats-small {
        font-size: 1.4rem; } }

#sub-category .overlay {
  margin-top: 1rem; }

.large-search {
  width: 100%;
  background-color: #111318;
  border: 1px solid #907140;
  height: 5rem;
  position: relative;
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.5); }
  .large-search.open {
    z-index: 7001; }
  .large-search__wrapper {
    position: relative; }
  .large-search__text {
    width: 98%;
    height: auto;
    padding: 1rem;
    margin-left: 0.7rem;
    margin-top: 0.6rem;
    background-color: #2c313e;
    color: #b1b1b2;
    border: 1px solid #dfbd5e; }
    @media (max-width: 75em) {
      .large-search__text {
        width: 97.7%; } }
    @media (max-width: 56.25em) {
      .large-search__text {
        margin-top: 0.4rem; } }
  .large-search__button {
    background-color: #000;
    border: 1px solid #dfbd5e;
    border-image-source: linear-gradient(#dfbd5e, #907140);
    border-image-slice: 14;
    color: #dfbd5e;
    font-weight: bold;
    font-family: "Cinzel", sans-serif;
    box-sizing: border-box;
    position: absolute;
    padding: 0.7rem 1.7rem;
    text-transform: uppercase;
    right: 12px;
    bottom: 2px; }
    @media (max-width: 37.5em) {
      .large-search__button {
        right: 6px; } }
  .large-search__button:disabled {
    opacity: 0.5;
    cursor: default; }
  .large-search__suggestions {
    margin-top: 1rem; }
  .large-search__suggestions ul {
    position: relative;
    z-index: 99;
    background-color: #2c313e;
    border: 1px solid #907140;
    max-height: 300px;
    overflow-y: scroll;
    padding: 1rem; }
  .large-search__suggestions li {
    cursor: pointer;
    padding: 0.5rem;
    color: #fff; }
  .large-search__suggestions li:hover {
    opacity: 0.5; }

.filter {
  background-color: #111318;
  width: 242px;
  height: 602px;
  border: 1px solid #907140;
  margin-bottom: 2rem;
  margin-top: -9rem; }
  .filter__section:not(:first-child) {
    margin-top: 4rem; }
  .filter__paragraph {
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: bold; }
  .filter__title {
    margin: 1.1rem 2rem;
    font-size: 1.6rem; }
  .filter__range {
    margin-left: 1rem;
    margin-top: 1rem; }
  .filter__input {
    background-color: #2c313e;
    color: #b1b1b2;
    border: 1px solid #d1bf8b;
    margin: 0.7rem;
    width: 225px;
    height: 37px;
    padding: 0.5rem; }
  .filter__choice {
    background-color: #2c313e;
    color: #b1b1b2;
    border: 1px solid #d1bf8b;
    margin: 0.7rem;
    width: 225px;
    height: 37px;
    padding: 0.5rem; }
  .filter__radio {
    margin-left: 1rem;
    display: none; }
  .filter__radio-label {
    color: #F0F0F0;
    font-size: 1.4rem;
    margin-left: 2rem;
    position: relative;
    cursor: pointer; }
  .filter__radio-button {
    height: 1rem;
    width: 1rem;
    border: 1px solid #F0F0F0;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: -14px;
    top: 5px; }
  .filter__radio-button:after {
    content: "";
    height: 0.6rem;
    width: 0.6rem;
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50.5%;
    transform: translate(-50%, -50%);
    background-color: #F0F0F0;
    opacity: 0;
    transition: opacity 0.2s; }
  .filter__radio:checked ~ .filter__radio-label .filter__radio-button:after {
    opacity: 1; }

.less-margin {
  margin-left: 1rem; }

.gold:after {
  background-color: #dfbd5e;
  top: 50%;
  left: 50%; }

.filters__mod {
  margin-bottom: 0.8rem; }

.pagination {
  text-align: center; }
  .pagination__body {
    display: flex;
    justify-content: center;
    align-items: center; }
  .pagination__background {
    background-color: #0b0d10;
    border: 1px solid #907140;
    padding: 1rem;
    margin: 0 1rem; }
  .pagination__background a {
    color: #F0F0F0;
    text-decoration: none;
    font-size: 1.3rem; }
  .pagination__background:active, .pagination__background:hover {
    border: 1px solid #dfbd5e; }
  .pagination__background a:active,
  .pagination__background a:hover {
    color: #dfbd5e; }

.close__element {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  font-size: 1.1rem;
  opacity: 0.5; }

.close__element:after {
  content: '✖';
  color: #F0F0F0;
  margin-left: 0.35rem;
  margin-top: 0.1rem; }

.breadcrumb {
  display: flex;
  justify-content: flex-start;
  margin-top: 2rem;
  margin-bottom: 1rem; }
  .breadcrumb__list a {
    color: #dfbd5e;
    text-decoration: none;
    font-size: 1.1rem; }
  .breadcrumb__list a:active,
  .breadcrumb__list a:hover {
    color: #F0F0F0; }
  .breadcrumb__arrow {
    fill: #dfbd5e;
    width: 10px;
    height: 10px;
    margin: 0 0.5rem;
    opacity: 0.5; }
  .breadcrumb__list:last-child .breadcrumb__arrow {
    display: none; }

.header {
  display: block;
  width: 100%;
  z-index: 1000;
  background-color: #0b0d10;
  border-bottom: 1px solid #4d4d4a;
  position: relative; }
  .header__main-logo {
    width: 230px;
    height: auto;
    padding: 0.5rem 0rem;
    padding-top: 1.2rem;
    margin-left: 2.3rem; }
  .header.minimal .header__main-logo {
    margin-left: 0; }
  .header__menu {
    fill: #dfbd5e;
    height: 25px;
    width: 25px; }
  .header__toggle,
  .header [id="drop"] {
    display: none; }
    @media (max-width: 37.5em) {
      .header__toggle,
      .header [id="drop"] {
        display: flex;
        cursor: pointer;
        justify-content: flex-end;
        margin: 1rem;
        position: absolute;
        top: 15px;
        right: 15px; } }
  .header__navigation {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none; }
    @media (max-width: 37.5em) {
      .header__navigation {
        flex-direction: column;
        justify-content: center; } }
  .header__navigation .header__list {
    margin: 1rem 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; }
  .header__navigation .header__list .anchor {
    color: #dfbd5e;
    padding: 1rem;
    text-decoration: none;
    text-transform: uppercase;
    transition: .4s;
    text-decoration: none;
    font-size: 1.5rem;
    border-bottom: 1px solid transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .header__navigation .header__list .anchor:active,
  .header__navigation .header__list .anchor:hover {
    border-bottom: 1px solid #d1bf8b;
    color: #F0F0F0;
    transition: .4s; }
  .header__second-header {
    text-align: center; }
  .header__notification {
    display: flex;
    display: none;
    justify-content: center;
    align-items: center;
    width: 1.8rem;
    height: 1.8rem;
    background-color: #B20000;
    color: #F0F0F0;
    border-radius: 50%;
    font-weight: bold;
    opacity: 0.9;
    font-size: 1.1rem;
    position: absolute;
    top: 0;
    right: 0; }
  .header__cart.has-items .header__notification {
    display: flex; }

@media (max-width: 37.5em) {
  .header__toggle + a,
  .header__navigation {
    display: none; } }

@media (max-width: 37.5em) {
  [id^=header__drop]:checked + ul {
    display: flex; } }

#header__drop {
  display: none; }

.sub-header {
  background-color: #13161e; }
  @media (max-width: 37.5em) {
    .sub-header {
      display: none; } }
  .sub-header__info {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    font-size: 1rem; }
  .sub-header__info li {
    margin: 1rem 0rem; }
  .sub-header__info li a {
    color: #F0F0F0;
    padding: 1.3rem;
    text-decoration: none;
    text-transform: uppercase;
    transition: ease-out all .4s;
    border-bottom: 1px solid transparent; }
  .sub-header__info li a:active,
  .sub-header__info li a:hover {
    border-bottom: 1px solid #907140;
    color: #dfbd5e;
    transition: ease-out all .4s; }
  .sub-header__second-sub-header {
    display: flex;
    justify-content: center;
    align-items: center; }

.interact {
  display: flex; }
  .interact__links-box {
    margin: 1rem 0.7rem;
    padding: 0.2rem;
    background-color: #F0F0F0;
    width: 20px;
    height: 20px; }
  .interact__social {
    width: 15px;
    height: 15px; }

.notification.row + .row {
  margin-top: 0; }

.notification__stack {
  font-size: 1.2rem;
  background-color: #000;
  text-align: center; }

.notification__inner {
  padding: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; }

.notification__icon {
  width: 20px;
  height: 20px;
  margin-right: 1.2rem; }

.notification__closer {
  opacity: 0.5;
  cursor: pointer; }

.notification__closer:hover {
  opacity: 0.9;
  cursor: pointer; }

.notification.success .notification__icon {
  fill: #20d00b; }

.notification.success .notification__message {
  color: #20d00b; }

.notification.info .notification__icon {
  fill: #dfbd5e; }

.notification.info .notification__message {
  color: #dfbd5e; }

.notification.error .notification__icon {
  fill: #B20000; }

.notification.error .notification__message {
  color: #B20000; }

.notification .notification__close {
  top: 25%; }

.yellow {
  color: #dfbd5e; }

.red {
  color: #B20000; }
  @media (max-width: 37.5em) {
    .red {
      width: 60%; } }
  @media (max-width: 26.25em) {
    .red {
      width: 70%; } }

.green {
  color: #20d00b; }

.inline__close {
  margin-bottom: 0 !important; }

.inline-main-header {
  height: auto; }

.inline__background {
  background-color: #2c313e;
  padding: 1rem; }

.mini-cart {
  display: none;
  position: absolute;
  top: 64px;
  left: -266px;
  z-index: 100; }
  @media (max-width: 75em) {
    .mini-cart {
      top: 58px;
      left: -276px; } }
  @media (max-width: 37.5em) {
    .mini-cart {
      left: -140px; } }
  .mini-cart__body {
    z-index: 4;
    width: 343px;
    border: 1px solid #907140;
    box-shadow: 0px 0 18px 20px rgba(0, 0, 0, 0.4); }
  .mini-cart__header {
    text-align: center; }
  .mini-cart__background {
    background-color: #2c313e;
    padding: 1rem; }
  .mini-cart__list {
    position: relative;
    background-color: #222939;
    padding: 1rem 0.2rem;
    height: auto;
    margin: 0.5rem;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    display: flex;
    justify-content: space-around; }
  .mini-cart__qty {
    font-size: 1.8rem;
    margin-left: 1rem; }
  .mini-cart__color {
    color: #dfbd5e; }
  .mini-cart__content {
    width: 145px;
    margin-left: 2rem; }
  .mini-cart__name {
    font-size: 1.2rem; }
  .mini-cart__total-price {
    margin-right: 2rem; }

.mini-profile {
  display: none;
  position: absolute;
  left: -230px;
  top: 64px;
  z-index: 100; }
  @media (max-width: 75em) {
    .mini-profile {
      top: 58px; } }
  @media (max-width: 37.5em) {
    .mini-profile {
      left: -125px; } }
  .mini-profile__body {
    z-index: 4;
    width: 343px;
    border: 1px solid #907140;
    box-shadow: 0px 0 18px 20px rgba(0, 0, 0, 0.4); }
  .mini-profile__background {
    background-color: #2c313e;
    padding: 1rem; }
  .mini-profile__header {
    text-align: center;
    margin-bottom: 1rem; }

.quick-view {
  z-index: 6001;
  position: absolute; }

.modal__body {
  z-index: 4;
  width: 343px;
  border: 1px solid #907140;
  box-shadow: 0px 0 18px 20px rgba(0, 0, 0, 0.4);
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  overflow: hidden;
  display: table;
  position: fixed; }

.modal__main-title {
  margin-bottom: 2rem;
  text-align: center; }

.modal__image {
  width: 100px;
  height: 100px;
  text-align: center;
  margin: 0 auto;
  display: flex; }

.modal__title {
  color: #dfbd5e;
  text-align: center; }

.modal__background {
  background-color: #2c313e;
  padding: 1rem; }

.modal__header {
  margin: 1rem 0;
  text-align: center; }

.modal__buttons {
  width: 97%;
  margin: 0 auto; }

.modal__line:before {
  content: "";
  background-image: -webkit-linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
  background-image: linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
  display: inline-block;
  height: 0.1rem;
  width: 75%;
  width: 100%; }

.modal__title {
  margin: 2rem 0 2rem 0.7rem; }

.modal__price {
  margin: 0 auto;
  font-size: 2rem;
  font-weight: bold; }

.modal__price-header {
  text-align: right; }

.modal__paragraph {
  margin: 2rem 0 2rem 0.7rem; }

.modal .category__choice-stats {
  display: block;
  margin: 0 auto;
  max-width: 100%; }

.stats {
  font-size: 0.9rem; }

.sticky {
  position: fixed;
  top: 0;
  left: 0; }

.row {
  max-width: 1200px;
  margin: 0 auto; }
  .row + .row {
    margin-top: 1.3%; }

[class^="col-"] {
  float: left;
  margin-right: 1.5%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  [class^="col-"]:last-child {
    margin-right: 0%; }
  @media (max-width: 37.5em) {
    [class^="col-"] {
      width: 100% !important;
      margin-bottom: 1rem; } }

/*
    .col-1  { width:5.583333333333333%; }
    .col-2  { width:14.16666666666667%; }
    .col-3  { width:22.75%; }
    .col-4  { width:31.33333333333333%; }
    .col-5  { width:39.91666666666667%; }
    .col-6  { width:48.5%; }
    .col-7  { width:57.08333333333333%; }
    .col-8  { width:65.66666666666666%; }
    .col-9  { width:74.25%; }
    .col-10 { width:82.83333333333333%; }
    .col-11 { width:91.41666666666666%; }
    .col-12 { width:100%; }
    */
.col-1 {
  width: 6.95833%; }

.col-2 {
  width: 15.41667%; }

.col-3 {
  width: 23.875%; }

.col-4 {
  width: 32.33333%; }

.col-5 {
  width: 40.79167%; }

.col-6 {
  width: 49.25%; }

.col-7 {
  width: 57.70833%; }

.col-8 {
  width: 66.16667%; }

.col-9 {
  width: 74.625%; }

.col-10 {
  width: 83.08333%; }

.col-11 {
  width: 91.54167%; }

.col-12 {
  width: 100%; }

.top-footer {
  background-color: #191d26;
  border-top: 1px solid #dfbd5e;
  padding: 2rem 0;
  /*opacity: 0.7;*/ }
  @media (max-width: 37.5em) {
    .top-footer {
      text-align: center; } }
  .top-footer__logo-footer {
    margin-top: 1rem;
    margin-left: 0.6rem;
    width: 112px;
    height: 19px; }
  .top-footer__menus {
    display: flex;
    flex-direction: column;
    list-style: none;
    font-size: 1.1rem; }
  .top-footer__menus li {
    margin: 1rem 0rem; }
  .top-footer__menus li a {
    color: #F0F0F0;
    text-decoration: none;
    text-transform: uppercase; }
  .top-footer__menus li a:active,
  .top-footer__menus li a:hover {
    border-bottom: 2px solid #907140;
    color: #dfbd5e;
    transition: ease-out all .4s; }
  .top-footer__second-footer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center; }

.footer {
  background-color: #13161e;
  border-top: 1px solid #4d4d4a;
  text-align: center; }
  .footer__paragraph {
    color: #dfbd5e;
    margin: 3rem; }
  .footer__payment {
    width: 605px;
    height: 50px; }
    @media (max-width: 37.5em) {
      .footer__payment {
        width: 100%; } }
  .footer__copyright {
    color: #F0F0F0;
    margin: 0.8rem 0; }

@media (max-width: 37.5em) {
  .responsive__interact {
    justify-content: center; } }

.content__main-title {
  text-align: center;
  text-transform: uppercase;
  color: #F0F0F0;
  font-size: 2rem;
  margin-bottom: 3rem;
  overflow: hidden;
  letter-spacing: 0.5rem;
  font-weight: 100; }

.content__body {
  background-color: #2c313e;
  border: 1px solid #dfbd5e;
  padding: 2rem; }

.content__body p {
  font-size: 1.2rem;
  line-height: 2.3rem;
  font-family: "Open-Sans", sans-serif;
  letter-spacing: 0.1rem;
  color: #F0F0F0;
  width: 97%; }
  @media (max-width: 37.5em) {
    .content__body p {
      width: 100%; } }

.content__lines:after, .content__lines:before {
  content: "";
  background-image: -webkit-linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
  background-image: linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
  display: inline-block;
  height: 0.1rem;
  width: 75%;
  width: 100%;
  margin: 0 auto;
  opacity: 0.5;
  display: block; }

.content__sub-title {
  color: #dfbd5e;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1rem; }

.content__sub-title:before, .content__sub-title:after {
  content: "";
  display: inline-block;
  height: 0.2rem;
  position: relative;
  vertical-align: middle;
  width: 15%; }
  @media (max-width: 37.5em) {
    .content__sub-title:before, .content__sub-title:after {
      display: none; } }

.content__sub-title:before {
  right: 1.5em;
  margin-left: -50%;
  background: #dfbd5e;
  background: linear-gradient(to left, #dfbd5e, transparent); }

.content__sub-title:after {
  left: 1.5em;
  margin-right: -50%;
  background: #dfbd5e;
  background: linear-gradient(to right, #dfbd5e, transparent); }

.content__section {
  margin-top: 5rem; }

.content__paragraph {
  text-align: center;
  font-family: "Open-Sans", sans-serif;
  margin: 2rem 0;
  font-size: 1.2rem; }

.accordion__background {
  background-color: #2c313e;
  padding: 1rem;
  border: 1px solid #dfbd5e; }

.accordion__question:after {
  content: "";
  background-image: -webkit-linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
  background-image: linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
  display: inline-block;
  height: 0.1rem;
  width: 75%;
  width: 100%; }

.accordion__question a {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: column;
  flex-direction: column;
  font-size: 1.5rem;
  font-weight: 400;
  color: #dfbd5e;
  padding: 0.5rem;
  margin-top: 1rem;
  width: 97%; }

.accordion__question a::after {
  content: "\002b";
  background-image: url(/images/plus.svg);
  position: absolute;
  right: -2rem;
  bottom: 0.5rem;
  color: #dfbd5e;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #dfbd5e;
  text-align: center; }
  @media (max-width: 37.5em) {
    .accordion__question a::after {
      border: transparent; } }

.accordion__question a.active::after {
  content: "\002d";
  color: #dfbd5e;
  border: 1px solid #dfbd5e; }
  @media (max-width: 37.5em) {
    .accordion__question a.active::after {
      border: transparent; } }

.accordion__answer {
  padding: 0.5rem;
  font-size: 1.3rem;
  font-family: "Open-Sans", sans-serif;
  display: none; }

.accordion__answer:after {
  content: "";
  background-image: -webkit-linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
  background-image: linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
  display: inline-block;
  height: 0.1rem;
  width: 75%;
  width: 100%; }

.accordion__answer p {
  margin: 1rem 0;
  /*letter-spacing: 0.1rem;*/
  line-height: 2rem;
  width: 90%; }
  @media (max-width: 37.5em) {
    .accordion__answer p {
      width: 100%; } }

.accordion__question a:hover,
.accordion__question a:hover::after {
  cursor: pointer;
  color: #F0F0F0; }

.accordion__image {
  width: 327px;
  height: 503px;
  display: block;
  margin: 2rem auto; }

.accordion .line-draw:after {
  height: 0; }

.more-spacing {
  padding: 3rem 0; }

.spacing {
  padding: 4rem 0; }

.less-spacing {
  padding: 3rem 0; }

.further-less-spacing {
  padding: 1rem 0; }

#subscribe {
  text-align: center;
  background-image: url(../images/subscribe.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top: 1px solid #dfbd5e;
  border-bottom: 1px solid #dfbd5e; }
  #subscribe .section__large-title {
    font-weight: normal;
    letter-spacing: 0.2rem; }
  #subscribe .section__info-paragraph {
    margin: 1rem 0; }
  #subscribe form {
    margin-top: 2rem; }

#shop {
  text-align: center;
  background-image: url(../images/shop.png);
  background-position: center;
  background-repeat: no-repeat;
  border-bottom: #dfbd5e; }

.section__title {
  text-align: center;
  text-transform: uppercase;
  color: #F0F0F0;
  font-size: 2rem;
  margin-bottom: 3rem;
  overflow: hidden;
  letter-spacing: 0.5rem;
  font-weight: 100; }

.section__title:before, .section__title:after {
  content: "";
  display: inline-block;
  height: 0.2rem;
  position: relative;
  vertical-align: middle;
  width: 34%; }

.section__title:before {
  right: 1.5em;
  margin-left: -50%;
  background: #dfbd5e;
  background: linear-gradient(to left, #dfbd5e, transparent); }

.section__title:after {
  left: 1.5em;
  margin-right: -50%;
  background: #dfbd5e;
  background: linear-gradient(to right, #dfbd5e, transparent); }

.section__image {
  position: relative; }

.section__image-discounted, .section__image-latest {
  width: 100px;
  height: 100px; }

.section__image-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 213px; }

.section__image-canvas {
  position: absolute;
  z-index: -1; }

.section__large-image {
  height: 410px;
  width: 100%; }
  @media (max-width: 75em) {
    .section__large-image {
      height: auto;
      margin-top: 0.5rem; } }

.section__blog-outter {
  width: 1300px;
  margin: 0 auto;
  overflow-y: scroll; }
  @media (max-width: 75em) {
    .section__blog-outter {
      width: auto; } }

.section__blog-title {
  font-size: 1.5rem;
  margin-top: 1rem; }
  @media (max-width: 56.25em) {
    .section__blog-title {
      margin-top: 0; } }

.section__blog-line {
  border: 0;
  height: 1px;
  background: linear-gradient(to right, #dfbd5e, transparent);
  margin-top: 1rem; }
  @media (max-width: 56.25em) {
    .section__blog-line {
      margin-top: 5rem; } }
  @media (max-width: 56.25em) {
    .section__blog-line {
      margin-top: 3rem; } }

.section__image-offer {
  width: 100px;
  height: 100px; }

.section__blog-paragraph {
  font-family: "Open-Sans", sans-serif;
  color: #907140;
  margin-top: 1rem; }

.section__date {
  font-family: "Open-Sans", sans-serif;
  color: #F0F0F0;
  margin-top: 1rem; }

.section__large-title {
  font-size: 3rem;
  margin-bottom: 2rem; }

.section__info-paragraph {
  font-size: 1.1rem;
  color: #dfbd5e;
  margin-bottom: 2rem; }

.section__hours:before, .section__hours:after {
  content: "";
  background-image: -webkit-linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
  background-image: linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
  display: inline-block;
  height: 0.1rem;
  width: 75%; }

.section__time {
  color: #dfbd5e;
  font-size: 4rem;
  display: flex;
  justify-content: flex-start;
  width: 100%; }

.section__time #days {
  width: 40%;
  text-align: right; }

.section__time #hours {
  width: 9%; }

.section__time #minutes {
  width: 9%; }

.section__time #seconds {
  width: 11%; }

.section__days {
  display: flex;
  justify-content: center;
  color: #F0F0F0;
  font-size: 1rem;
  margin-left: 4rem;
  margin-top: 1rem; }

.section__days li {
  padding-right: 2rem;
  font-size: 2rem;
  list-style: none; }

.slider__background {
  background-color: #111318;
  border: 1px solid #907140;
  margin-top: 1rem;
  padding: 2rem;
  padding-top: 0.3rem;
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.5); }

.slider__title-primary {
  color: #dfbd5e;
  font-size: 1.3rem;
  margin: 1rem 0; }

.slider__image-content-small {
  height: 152px;
  position: relative;
  z-index: 1; }

.slider__image-canvas-small {
  position: absolute;
  z-index: 0;
  /*box-shadow: 0 0 0 100px rgba(0,0,0, 0.5) inset;*/
  opacity: 0.3; }

.slider__image-content-small {
  display: flex;
  justify-content: center;
  align-items: center; }

.slider__image-offer {
  width: 100px;
  height: 100px; }

.sub-categories .slider__image-content-small {
  /*height: 100%;*/
  height: 100px;
  width: 100px; }

.sub-categories .slider__image-canvas-small {
  height: 100%; }

.search__text {
  background-color: #2c313e;
  border: 1px solid #4d4d4a;
  color: #b1b1b2;
  width: 40%;
  padding: 1rem; }

.search__subscribe--button {
  background-color: #000;
  border: 1px solid #dfbd5e;
  border-image-source: linear-gradient(#dfbd5e, #907140);
  border-image-slice: 14;
  color: #dfbd5e;
  font-weight: bold;
  font-family: "Cinzel", sans-serif;
  box-sizing: border-box;
  position: absolute;
  padding: 0.89rem; }

.owl-stage-outer {
  padding: 0.2rem 0; }

.owl-theme .owl-dots {
  position: absolute;
  top: -2rem;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.5; }
  @media (max-width: 37.5em) {
    .owl-theme .owl-dots {
      display: none; } }

.owl-theme .owl-dots:hover {
  opacity: 1; }

.owl-theme .owl-dots .owl-dot span {
  opacity: 0.4;
  width: 7px;
  height: 7px;
  margin: 0px 3px; }

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #dfbd5e;
  opacity: 0.8; }

.owl-carousel .owl-item .section__image-canvas,
.owl-carousel .owl-item .slider__image-canvas-small {
  height: 100%; }

.owl-carousel .owl-item .section__image-offer,
.owl-carousel .owl-item .slider__image-offer {
  width: auto; }

.owl-carousel .owl-item {
  box-shadow: 0px 0px 20px 20px rgba(0, 0, 0, 0.5); }

.owl-theme .owl-nav {
  margin: 0;
  display: none; }

.owl-theme .owl-nav .owl-prev,
.owl-theme .owl-nav .owl-next {
  top: 50%;
  transform: translateY(-50%);
  position: absolute; }

.owl-theme:hover .owl-nav,
.owl-theme:hover .owl-nav {
  display: block; }

.owl-theme .owl-nav .owl-prev {
  left: -2rem; }

.owl-theme .owl-nav .owl-next {
  right: -2rem; }

.owl-theme .owl-nav .owl-prev img {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH"; }

.owl-theme .owl-nav .owl-next img,
.owl-theme .owl-nav .owl-prev img {
  /*padding: 1rem;*/ }

.slider__background .owl-theme .owl-nav {
  /*margin-top: 10px;*/ }

.slider__background .owl-nav {
  /*width: 857px;*/ }

.slider__background .owl-nav .owl-prev {
  /*left:7px;*/ }

.slider__background .owl-nav .owl-next {
  /*right: 5px;*/ }

.owl-theme .owl-nav [class*=owl-]:hover,
.owl-theme .owl-nav [class*=owl-]:focus {
  background: transparent;
  outline: none; }

.owl-carousel:hover .owl-nav {
  display: block; }

.c-product-filter {
  display: flex;
  justify-content: right;
  margin-top: -1rem; }
  .c-product-filter-buyable__label {
    color: #F0F0F0;
    margin-right: 1em; }
  .c-product-filter-buyable__select {
    background-color: #2c313e;
    color: #b1b1b2;
    border: 1px solid #d1bf8b;
    padding: 0.5rem; }

.c-product-list__btn-load-more {
  display: none; }

.c-product--in-modal .c-product__box-price {
  margin: 0; }

.c-product--in-listing {
  display: none;
  background-color: #111318;
  width: 100%;
  height: 150px;
  border: 1px solid #907140;
  padding-bottom: 1rem;
  margin-top: 2rem; }
  @media (max-width: 37.5em) {
    .c-product--in-listing {
      height: 395px; } }
  @media (max-width: 26.25em) {
    .c-product--in-listing {
      height: 385px; } }

.c-product.is-loaded {
  display: block; }

.c-product.is-not-buyable {
  opacity: 0.5; }

.c-product__image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px; }

.c-product__img {
  width: 100px;
  height: 100px; }

.c-product__name {
  margin-left: -1.3rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-size: 1.5rem; }
  @media (max-width: 75em) {
    .c-product__name {
      margin-left: 2.6rem; } }
  @media (max-width: 56.25em) {
    .c-product__name {
      margin-top: 0.5rem;
      margin-left: 4.7rem; } }
  @media (max-width: 37.5em) {
    .c-product__name {
      margin-left: 1.1rem;
      font-size: 2.1rem; } }

.c-product__stats-select {
  background-color: #2c313e;
  color: #b1b1b2;
  border: 1px solid #d1bf8b;
  margin: 0.7rem;
  width: 225px;
  height: 37px;
  padding: 0.5rem;
  max-width: 100%;
  margin-left: -1.3rem;
  margin-top: 0.3rem; }
  @media (max-width: 75em) {
    .c-product__stats-select {
      margin-left: 2.3rem; } }
  @media (max-width: 56.25em) {
    .c-product__stats-select {
      width: 60%;
      margin-left: 4.7rem;
      margin-top: 1.9rem; } }
  @media (max-width: 37.5em) {
    .c-product__stats-select {
      margin-left: 1.1rem; } }

.c-product__description {
  display: none;
  margin-left: -1.3rem;
  margin-top: 1rem;
  font-family: "Open-Sans", sans-serif;
  color: #907140; }
  @media (min-width: 112.5em) {
    .c-product__description {
      margin-top: 0.8rem; } }
  @media (max-width: 75em) {
    .c-product__description {
      margin-left: 2.6rem;
      margin-top: 1rem; } }
  @media (max-width: 56.25em) {
    .c-product__description {
      margin-left: 4.6rem; } }
  @media (max-width: 37.5em) {
    .c-product__description {
      font-size: 1.7rem;
      margin-left: 1.1rem; } }

.c-product__box-qty-price {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px; }
  @media (max-width: 37.5em) {
    .c-product__box-qty-price {
      margin-top: -0.4rem; } }

.c-product__qty-select {
  background-color: #2c313e;
  color: #b1b1b2;
  border: 1px solid #d1bf8b;
  margin: 0.7rem;
  width: 225px;
  height: 37px;
  padding: 0.5rem;
  width: 60px;
  /*margin-left: 10.8rem;*/ }
  @media (max-width: 75em) {
    .c-product__qty-select {
      margin-left: 9.1rem; } }
  @media (max-width: 37.5em) {
    .c-product__qty-select {
      margin-left: 1.1rem;
      width: 100%; } }

.c-product__box-price {
  margin: 0 auto;
  /*text-align: center;*/
  /*padding-right: 4rem;*/ }
  @media (max-width: 37.5em) {
    .c-product__box-price {
      text-align: left;
      margin-left: 1.5rem;
      font-size: 2.5rem; } }

.c-product__price {
  font-size: 2rem; }

.c-product__price-before {
  color: #4d4d4a;
  font-size: 1.4rem;
  margin-right: 0.4rem;
  text-decoration: line-through;
  display: inline-block; }
  @media (max-width: 37.5em) {
    .c-product__price-before {
      font-size: 2.2rem; } }

.c-product__btn-addtocart :disabled {
  cursor: default;
  opacity: 0.5; }

/*.navigation{
    margin-top: 1rem;
    font-size: 2rem;
    margin-top:48px;

}*/
/*.category{*/
/*background-color: $color-darker;
    width:100%;
    height:150px;
    border:1px solid $color-primary-dark;
    padding-bottom:1rem;
    margin-top: 2rem;*/
/*@include respond(phone) { //600px
        height:395px;
    }

    @include respond(extra-small-phone) {
        height:385px;
    }*/
/*&__list{
        display: flex;
        justify-content: space-between;

        @include respond(phone) { //600px
            flex-direction: column;
        }
    }*/
/*&__list li{
        list-style: none;
    }*/
/* &__image{
        width: 100px;
        height: 100px;
    }*/
/*&__title{
        margin-left: -1.3rem;
        margin-top: 1.5rem;
        margin-bottom: 1rem;
        font-size: 1.5rem;

        @include respond(tab-land) { //1200px
            margin-left: 2.6rem;
        }

        @include respond(tab-port) { //900px
            margin-top: 0.5rem;
            margin-left: 4.7rem;
        }

        @include respond(phone) { //600px
            margin-left: 1.1rem;
            font-size: 2.1rem;
        }
    }*/
/*&__paragraph{
        margin-left: -1.3rem;
        margin-top: 1rem;
        font-family: "Open-Sans", sans-serif;
        color: $color-primary-dark;

        @include respond(big-desktop) { //1800px
            margin-top: 0.8rem;
        }

        @include respond(tab-land) { //1200px
            margin-left: 2.6rem;
            margin-top: 1rem;
        }

        @include respond(tab-port) { //900px
            margin-left: 4.6rem;
        }

        @include respond(phone) { //600px
            font-size:1.7rem;
            margin-left: 1.1rem;
        }
    }*/
/*&__image-section{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 150px;
    }*/
/*&__choice-stats{
        @include forms;
        max-width:100%;
        margin-left: -1.3rem;
        margin-top: 0.3rem;


        @include respond(tab-land) { //1200px
            margin-left: 2.3rem;
        }

        @include respond(tab-port) { //900px
            width: 60%;
            margin-left: 4.7rem;
            margin-top: 1.9rem;
        }

        @include respond(phone) { //600px
            margin-left:1.1rem;
        }
    }*/
/*&__choice{
        @include forms;
        width: 60px;
        margin-left: 10.8rem;

        @include respond(tab-land) { //1200px
            margin-left: 9.1rem;
        }

        @include respond(phone) { //600px
            margin-left: 1.1rem;
            width: 100%;
        }
    }*/
/*&__prices {
        text-align: center;
        margin: 0 auto;
        padding-right: 4rem;

        @include respond(phone) { //600px
            text-align: left;
            margin-left: 1.5rem;
            font-size: 2.5rem;
        }
    }*/
/*&__price {
        font-size: 2rem;
    }*/
/*&__price-special {
        color: $color-grey;
        font-size: 1.4rem;
        margin-right: 0.4rem;
        text-decoration: line-through;
        display: inline-block;

        @include respond(phone) { //600px
            font-size: 2.2rem;
        }
    }*/
/*&__svg{
        text-align: right;
        margin-right: 1rem;

        @include respond(phone) { //600px
            text-align: left;
            margin-left:1.5rem;
        }
    }*/
/*&__choices{
        display: flex;
        align-items: center;
        margin-top: 48px;

        @include respond(phone) { //600px
            margin-top: -0.4rem;
        }
    }*/
/*}*/
.bonus {
  text-align: center;
  font-family: "Open-Sans", sans-serif; }
  .bonus__icon {
    fill: #F0F0F0;
    width: 50px;
    height: 50px; }
  .bonus__title {
    margin-top: 1rem;
    color: #d1bf8b;
    font-weight: bold;
    font-size: 1.5rem; }
  .bonus__sub-title {
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: 1.5rem; }
  .bonus__paragraph {
    text-align: left;
    margin-bottom: 4rem; }
  .bonus__main-section {
    text-align: center;
    margin: 0 auto; }
  .bonus__purchase-method {
    width: 70%;
    margin: 0 auto; }
    @media (max-width: 75em) {
      .bonus__purchase-method {
        width: 100%; } }
  .bonus__list {
    color: #F0F0F0;
    width: 850px;
    height: 192px;
    margin: 2rem 0;
    font-size: 2rem;
    font-family: "Open-Sans", sans-serif;
    border: 1px solid #907140;
    display: flex;
    flex-direction: column; }
    @media (max-width: 56.25em) {
      .bonus__list {
        width: 100%; } }
  .bonus__list p {
    padding: 0.48rem 0;
    display: flex;
    justify-content: space-around; }
    @media (max-width: 75em) {
      .bonus__list p {
        padding: 0.55rem 0; } }
    @media (max-width: 56.25em) {
      .bonus__list p {
        padding: 0.72rem 0; } }
    @media (max-width: 26.25em) {
      .bonus__list p {
        padding: 0.88rem 0; } }
  .bonus__list p:nth-child(odd) {
    background-color: #2c313e; }
  .bonus__list p:nth-child(even) {
    background-color: #191d26; }
  .bonus__list span {
    align-self: flex-end; }
  .bonus__pool {
    font-size: 1.5rem;
    margin: 4rem; }
  .bonus__pool-money {
    display: flex;
    justify-content: space-around;
    color: #dfbd5e; }
  .bonus__pool-money li {
    list-style: none; }
  .bonus__pool-money li a {
    text-decoration: none;
    color: #dfbd5e;
    font-size: 1.5rem; }
  .bonus__pool-money li a:active,
  .bonus__pool-money li a:hover {
    border-bottom: 2px solid #d1bf8b;
    color: #F0F0F0; }
  .bonus__gallery-section {
    background-color: #2c313e;
    border: 1px solid #907140;
    padding: 1.5rem;
    margin-bottom: 2rem; }
  .bonus__gallery:after {
    content: "";
    background-image: -webkit-linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
    background-image: linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
    display: inline-block;
    height: 0.1rem;
    width: 75%;
    width: 100%; }

.no-margin {
  margin-right: 0; }

.faq {
  cursor: pointer;
  text-align: center; }
  .faq__icon {
    fill: #F0F0F0;
    width: 50px;
    height: 50px; }
  .faq__title {
    font-family: "Open-Sans", sans-serif;
    color: #d1bf8b;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    display: block;
    font-size: 1.5rem; }
  .faq__title:hover {
    color: #F0F0F0; }

.info__questions li {
  border: 1px solid #dfbd5e;
  padding: 2rem;
  list-style: none;
  border-right-style: none;
  background-color: #191d26; }
  @media (max-width: 37.5em) {
    .info__questions li {
      border: 1px solid #dfbd5e; } }

.info__sub-title {
  text-align: center;
  margin-bottom: 3rem;
  font-size: 2rem; }

.info__questions li a {
  color: #F0F0F0;
  text-decoration: none;
  font-family: "Open-Sans",sans-serif; }

.info__title {
  font-family: "Open-Sans", sans-serif; }

.info__description {
  border: 1px solid #dfbd5e;
  padding: 2rem;
  background-color: #2c313e; }

.info__answers {
  color: #F0F0F0;
  margin: 1rem 0;
  font-family: "Open-Sans",sans-serif;
  line-height: 1.5rem;
  font-size: 1.1rem; }

.info__icon {
  fill: #F0F0F0;
  width: 20px;
  height: 20px; }

.info__svgs {
  display: flex; }

.info__rating {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap; }

.info__answer-small {
  align-self: flex-end;
  margin-left: -11.5rem; }

.line-draw:after {
  content: "";
  background-image: -webkit-linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
  background-image: linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
  display: inline-block;
  height: 0.1rem;
  width: 75%;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 4rem; }

.down {
  margin-right: 1rem; }

.up {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH"; }

.categories-section {
  position: relative;
  margin-top: 1rem; }

.image {
  background-color: #191d26;
  padding: 1rem;
  border: 1px solid #907140;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; }

.image:after {
  content: " ";
  width: 100px;
  height: 100px;
  background: url(../images/clothing.png);
  position: absolute;
  z-index: 1; }

.nav-menu {
  font-size: 1.8rem;
  margin-bottom: 0.5rem; }
  @media (max-width: 37.5em) {
    .nav-menu {
      text-align: center; } }

.products {
  display: flex;
  flex-direction: column; }
  .products__main-title, .products__sub-title {
    display: flex;
    justify-content: space-between; }
  .products__main-title {
    font-size: 1.9rem;
    font-weight: bold; }
    @media (max-width: 56.25em) {
      .products__main-title {
        font-size: 1.5rem; } }
  .products__original-price {
    color: #4d4d4a;
    font-size: 1.3rem;
    align-self: flex-end;
    position: relative; }
  .products__original-price:after {
    content: "";
    width: 3rem;
    height: 0.1rem;
    background-color: #4d4d4a;
    display: block;
    position: absolute;
    top: 0.9rem; }
  .products__sub-title {
    font-size: 1.9rem;
    font-weight: bold; }
    @media (max-width: 56.25em) {
      .products__sub-title {
        font-size: 1.5rem; } }
  .products__retail-price {
    font-size: 1.8rem;
    align-self: flex-end; }

.products:after,
.products:before {
  content: "";
  background-image: -webkit-linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
  background-image: linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
  display: inline-block;
  height: 0.1rem;
  width: 75%;
  width: 100%;
  margin: 0.3rem 0; }

.categories {
  text-align: right; }
  .categories__choice {
    background-color: #2c313e;
    color: #b1b1b2;
    border: 1px solid #d1bf8b;
    margin: 0.7rem;
    width: 225px;
    height: 37px;
    padding: 0.5rem;
    margin-right: 0;
    width: 277px; }
    @media (max-width: 56.25em) {
      .categories__choice {
        width: 100%; } }
  .categories__skin {
    text-align: center;
    margin-left: -7rem; }
    @media (max-width: 75em) {
      .categories__skin {
        margin-left: -15rem; } }
    @media (max-width: 56.25em) {
      .categories__skin {
        margin-left: -17rem; } }
    @media (max-width: 37.5em) {
      .categories__skin {
        text-align: left;
        margin-left: 0; } }
  .categories__quantity {
    text-align: center;
    margin-left: -5rem;
    margin-top: 0.5rem; }
    @media (max-width: 75em) {
      .categories__quantity {
        margin-left: -17rem; } }
    @media (max-width: 75em) {
      .categories__quantity {
        margin-left: -15rem; } }
    @media (max-width: 37.5em) {
      .categories__quantity {
        text-align: left;
        margin-left: 0; } }
  .categories__svg {
    margin-top: 1.3rem;
    display: flex;
    justify-content: flex-end; }
    @media (max-width: 37.5em) {
      .categories__svg {
        justify-content: flex-start; } }
  .categories__span {
    margin-right: 2rem; }
  .categories__heart {
    width: 20px;
    height: 20px;
    fill: #F0F0F0;
    margin-top: 0.3rem;
    margin-top: -0.6rem;
    margin-right: 0.5rem; }
  .categories__paragraph {
    font-size: 1.2rem;
    margin-top: 1.3rem;
    font-weight: bold; }
  .categories__time {
    width: 5rem;
    margin-right: 3rem; }
  .categories__wishlist {
    width: 4rem; }

.add {
  margin-right: 0; }

.navigations {
  text-align: center;
  margin: 3rem 0;
  margin-top: 0; }
  .navigations__lists {
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (max-width: 56.25em) {
      .navigations__lists {
        justify-content: space-between;
        flex-direction: column; } }
  .navigations__list {
    list-style: none;
    margin: 0 100px; }
  .navigations__list a {
    color: #dfbd5e;
    text-decoration: none;
    font-size: 1.5rem;
    transition: 0.5s;
    opacity: 0.7; }
    @media (max-width: 56.25em) {
      .navigations__list a {
        margin-right: 0; } }
  .navigations__list a:hover {
    opacity: 1; }
  .navigations__list a:hover {
    color: #F0F0F0;
    transition: 0.5s; }

.page-account .navigations .dashboard a,
.page-account-orders .navigations .orders a,
.page-tracking .navigations .tracking a {
  font-size: 2.5rem;
  opacity: 1; }

.navigations:after {
  content: "";
  background-image: -webkit-linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
  background-image: linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
  display: inline-block;
  height: 0.1rem;
  width: 75%;
  width: 60%;
  text-align: center; }

.user-background {
  background-color: #2c313e;
  border: 1px solid #907140;
  width: 100%;
  height: auto;
  padding: 2rem; }

.user__name {
  font-size: 1.8rem; }

.user__coins {
  font-size: 1.2rem;
  margin-top: 1.3rem; }

.user__since {
  margin: 2rem 0; }

.user__profile {
  display: flex;
  width: 100%;
  list-style: none;
  color: #dfbd5e; }

.user__profile li:nth-child(1) {
  width: 38%; }

.user__profile li:nth-child(2) {
  width: 22%; }

.user__profile li:nth-child(3) {
  width: 30%; }

.user__processes {
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  list-style: none;
  margin-bottom: 2rem;
  margin-top: 0.5rem; }

.user__inforamtion, .user__process {
  list-style: none; }

.user__information a {
  text-decoration: none;
  color: #dfbd5e; }

.user__process a {
  color: #F0F0F0;
  text-decoration: none; }

.profile__form-title {
  color: #F0F0F0; }

.profile__input-register, .profile__choice {
  background-color: #2c313e;
  color: #b1b1b2;
  border: 1px solid #d1bf8b;
  margin: 0.7rem;
  width: 225px;
  height: 37px;
  padding: 0.5rem;
  margin-left: 0; }

.title {
  text-align: center;
  font-size: 1.4rem; }

.lines:after {
  content: "";
  background-image: -webkit-linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
  background-image: linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
  display: inline-block;
  height: 0.1rem;
  width: 75%;
  width: 100%;
  margin: 2rem 0; }
  @media (max-width: 56.25em) {
    .lines:after {
      margin: 5rem 0; } }
  @media (max-width: 26.25em) {
    .lines:after {
      margin: 0 13rem; } }

.order-detail {
  background-color: #2c313e;
  border: 1px solid #907140;
  width: 100%;
  height: 50px;
  padding: 1rem;
  margin-bottom: 1rem;
  font-size: 1.2rem; }
  .order-detail__lists {
    display: flex;
    justify-content: space-between;
    align-content: flex-start;
    list-style: none; }
  .order-detail__list {
    list-style: none;
    width: 100%; }
  .order-detail__list a {
    color: #F0F0F0;
    text-decoration: none; }

.price-tag {
  text-align: right; }

.mule__title {
  text-align: center; }

.mule__background {
  text-align: center;
  margin-top: 5rem;
  background-color: #2c313e;
  border: 1px solid #907140;
  margin: 2rem 0; }

.mule__ground {
  background-color: #191d26;
  width: 350px;
  height: auto;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.5);
  margin: 5rem auto;
  padding: 1.5rem; }
  @media (max-width: 56.25em) {
    .mule__ground {
      width: 100%; } }

.mule__paragraph {
  margin: 0.5rem;
  font-size: 1.3rem; }

.mule__timer-title, .mule__time {
  font-size: 1.3rem; }

.mule__character-title, .mule__character {
  font-size: 1.8rem;
  font-weight: bold; }

.mule__icon {
  width: 50px;
  height: 50px;
  fill: #dfbd5e;
  margin-top: 0.5rem; }

.mule__time:after {
  content: "";
  background-image: -webkit-linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
  background-image: linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
  display: inline-block;
  height: 0.1rem;
  width: 75%;
  width: 50%; }

.credits__filters {
  margin-left: -1rem;
  margin-top: 0.5rem; }

.credits {
  text-align: center; }
  .credits__title {
    text-align: center;
    margin: 2rem 0; }
  .credits__payment, .credits__history {
    background-color: #2c313e;
    border: 1px solid #907140;
    width: auto;
    height: 630px;
    padding: 1rem;
    position: relative; }
    @media (max-width: 75em) {
      .credits__payment, .credits__history {
        height: 920px; } }
    @media (max-width: 56.25em) {
      .credits__payment, .credits__history {
        height: 980px; } }
  .credits__sub-title {
    display: flex;
    justify-content: space-around;
    padding: 2rem;
    list-style: none;
    color: #dfbd5e;
    font-size: 1.2rem; }
  .credits__overviews {
    display: flex;
    justify-content: space-evenly;
    list-style: none;
    background-color: #191d26;
    width: auto;
    height: auto;
    padding: 1rem;
    margin: 1rem 0; }
  .credits__amount-title {
    margin-top: 1rem; }
  .credits__amount {
    background-color: #2c313e;
    color: #b1b1b2;
    border: 1px solid #d1bf8b;
    margin: 0.7rem;
    width: 225px;
    height: 37px;
    padding: 0.5rem;
    margin-left: 0; }
  .credits__overview {
    height: 530px;
    overflow-y: scroll; }
    @media (max-width: 56.25em) {
      .credits__overview {
        height: 700px; } }
  .credits__overviews li a {
    color: #F0F0F0;
    text-decoration: none;
    margin-left: -1rem; }
  .credits__card {
    margin: 3rem 0; }
  .credits__filters {
    display: flex; }
  .credits__methods {
    margin: 2rem 0; }
  .credits__method-box {
    width: 163px;
    height: 62px;
    background-color: #222939;
    margin: 1rem 2rem 0 0; }
    @media (max-width: 75em) {
      .credits__method-box {
        width: 100%; } }
  .credits__method-box:active, .credits__method-box:hover {
    background-color: #dfbd5e;
    cursor: pointer; }
  .credits__input-register {
    background-color: #2c313e;
    color: #b1b1b2;
    border: 1px solid #d1bf8b;
    margin: 0.7rem;
    width: 225px;
    height: 37px;
    padding: 0.5rem;
    margin-left: 0; }
    @media (max-width: 37.5em) {
      .credits__input-register {
        width: 100%; } }
  .credits__form-title {
    color: #F0F0F0;
    font-family: "Open-Sans", sans-serif; }

.credits:after {
  content: "";
  background-image: -webkit-linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
  background-image: linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
  display: inline-block;
  height: 0.1rem;
  width: 75%;
  width: 80%; }

.wishlist {
  display: flex; }
  .wishlist__radio-label {
    color: #F0F0F0;
    margin-top: 2rem;
    margin-right: 0.5rem; }
  .wishlist__lists {
    display: flex;
    width: 100%;
    color: #F0F0F0;
    list-style: none;
    font-size: 1.5rem; }
    @media (max-width: 56.25em) {
      .wishlist__lists {
        display: none; } }
  .wishlist__background {
    width: 100%;
    height: 66px;
    background-color: #111318;
    border: 1px solid #907140;
    margin: 0 auto;
    position: relative;
    margin-top: 1rem; }
    @media (max-width: 56.25em) {
      .wishlist__background {
        height: auto;
        width: 100%;
        padding: 1rem; } }
  .wishlist__lists li:nth-child(1) {
    width: 41%; }
  .wishlist__lists li:nth-child(2) {
    width: 21.5%; }
  .wishlist__lists li:nth-child(3) {
    width: 17%; }
  .wishlist__preview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    font-size: 1.2rem;
    font-weight: bold; }
    @media (max-width: 56.25em) {
      .wishlist__preview {
        flex-direction: column; } }
  @media (max-width: 56.25em) {
    .wishlist__preview li {
      width: 100%;
      margin: 1rem 0; } }
  .wishlist__preview a {
    text-decoration: none;
    color: #d1bf8b; }
  .wishlist__price {
    color: #4d4d4a;
    position: relative; }
  .wishlist__price:after {
    content: "";
    width: 3rem;
    height: 0.1rem;
    background-color: #4d4d4a;
    display: block;
    position: absolute;
    top: 0.7rem; }

@media (max-width: 56.25em) {
  .responsive__close {
    position: absolute;
    right: 5px;
    top: 5px; } }

@media (max-width: 56.25em) {
  .wishlist__preview li:nth-child(1)::before {
    content: "Item:";
    color: #F0F0F0; }
  .wishlist__preview li:nth-child(3)::before {
    content: "Stats:";
    color: #F0F0F0; }
  .wishlist__preview li:nth-child(4)::before {
    content: "Server:";
    color: #F0F0F0; }
  .wishlist__preview li:nth-child(5)::before {
    content: "Price:";
    color: #F0F0F0; } }

.order {
  display: flex; }
  .order__radio-label {
    color: #F0F0F0;
    margin-top: 2rem;
    margin-right: 0.5rem;
    font-size: 0.9rem; }
  .order__sort {
    display: flex; }
  .order__lists-section {
    display: flex;
    width: 100%;
    align-items: center;
    color: #F0F0F0;
    list-style: none;
    font-size: 1.2rem; }
  .order__lists-section li:nth-child(1) {
    width: 28%; }
    @media (max-width: 37.5em) {
      .order__lists-section li:nth-child(1) {
        width: 100%; } }
  .order__lists-section li:nth-child(2) {
    width: 21%; }
    @media (max-width: 56.25em) {
      .order__lists-section li:nth-child(2) {
        margin-left: 1.5rem; } }
    @media (max-width: 37.5em) {
      .order__lists-section li:nth-child(2) {
        display: none; } }
  .order__lists-section li:nth-child(3) {
    width: 21%; }
    @media (max-width: 37.5em) {
      .order__lists-section li:nth-child(3) {
        display: none; } }
  .order__lists-section li:nth-child(4) {
    width: 14%; }
    @media (max-width: 37.5em) {
      .order__lists-section li:nth-child(4) {
        display: none; } }
  @media (max-width: 37.5em) {
    .order__lists-section li:nth-child(5) {
      display: none; } }
  .order__background {
    width: 100%;
    height: auto;
    background-color: #111318;
    border: 1px solid #dfbd5e;
    margin: 5rem auto;
    padding: 2rem;
    position: relative;
    margin-top: 1rem; }
  .order__preview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    font-size: 1.3rem;
    font-weight: bold; }
    @media (max-width: 37.5em) {
      .order__preview {
        flex-direction: column;
        align-items: flex-start; } }
  .order__history {
    background-color: #222939;
    border: 1px solid #dfbd5e;
    height: 500px;
    margin-top: 5rem; }
  .order__title {
    color: #dfbd5e;
    text-align: center;
    margin: 1rem 0; }
  .order__display ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 2rem 0;
    color: white; }
  .order__details {
    background-color: #222939;
    border: 1px solid #dfbd5e;
    margin-top: 5rem;
    padding: 1rem; }
  .order__item {
    overflow-y: scroll;
    height: 400px; }
  .order__items {
    background-color: #0b0d10;
    padding: 0.4rem;
    margin: 1rem 0; }
  .order__preview li {
    height: 64px; }
    @media (max-width: 37.5em) {
      .order__preview li {
        height: auto;
        width: 100%;
        margin: 1rem 0; } }
  .order__preview li a {
    color: #dfbd5e;
    text-decoration: none; }
  .order__title-section {
    color: #dfbd5e;
    font-size: 1.4rem;
    margin-top: 1rem; }
  .order__section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem; }
  .order__total {
    background-color: #222939;
    border: 1px solid #dfbd5e;
    font-weight: bold;
    text-align: right;
    padding: 1rem;
    width: 200px; }
  .order__total p {
    margin: 0.5rem 0;
    font-size: 1.2rem; }
  .order__vat, .order__coupon {
    color: #907140;
    font-size: 1.1rem; }

.cart__table-head {
  display: flex;
  width: 100%;
  color: #F0F0F0;
  font-size: 1.2rem;
  list-style: none;
  font-weight: bold;
  cursor: default; }

.cart__table-head li:nth-child(1) {
  width: 47.5%; }
  @media (max-width: 37.5em) {
    .cart__table-head li:nth-child(1) {
      width: 41.5%; } }
  @media (max-width: 26.25em) {
    .cart__table-head li:nth-child(1) {
      width: 33.5%; } }

.cart__table-head li:nth-child(2) {
  width: 16%; }
  @media (max-width: 75em) {
    .cart__table-head li:nth-child(2) {
      width: 18%; } }
  @media (max-width: 37.5em) {
    .cart__table-head li:nth-child(2) {
      width: 14%; } }

.cart__table-head li:nth-child(3) {
  width: 15.8%; }
  @media (max-width: 26.25em) {
    .cart__table-head li:nth-child(3) {
      width: 28.8%; } }

.cart__bonus {
  margin-top: 5rem; }

.cart__bonus:before {
  content: "";
  background-image: -webkit-linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
  background-image: linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
  display: inline-block;
  height: 0.1rem;
  width: 75%;
  width: 100%;
  margin-bottom: 4rem;
  opacity: 0.3; }

.cart__button-checkout {
  text-decoration: none;
  text-align: center;
  font-size: 1.4rem; }

.cart-product {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #111318;
  border: 1px solid #dfbd5e;
  padding: 1rem;
  margin-top: 0.5rem; }
  .cart-product__name {
    font-size: 1.2rem;
    margin-left: 1rem; }
  .cart-product__name p {
    width: 326px;
    word-break: break-all; }
  .cart-product__name span {
    color: #d1bf8b; }
  .cart-product__price, .cart-product__price-total {
    color: #d1bf8b;
    font-size: 1.5rem;
    width: 7rem; }
  .cart-product__quantity-select {
    width: 5em; }
  .cart-product__price-special {
    color: #4d4d4a;
    position: relative; }
  .cart-product__price-special:after {
    content: "";
    width: 3rem;
    height: 0.1rem;
    background-color: #4d4d4a;
    display: block;
    position: absolute;
    width: 4rem;
    top: 0.7rem; }
  .cart-product__price-total {
    display: flex;
    flex-direction: column; }
    @media (max-width: 75em) {
      .cart-product__price-total {
        width: 8rem; } }
  .cart-product__icon-remove {
    cursor: pointer;
    opacity: 0.3; }
  .cart-product__icon-remove:hover {
    opacity: 1; }

.cart-summary {
  background: #222939;
  border: 1px solid #dfbd5e;
  padding: 1rem;
  font-size: 1.2rem;
  margin-top: 2rem; }
  .cart-summary__totals {
    list-style-type: none;
    margin-top: 2rem; }
  .cart-summary__totals li:nth-last-child(1):before {
    content: "";
    background-image: -webkit-linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
    background-image: linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
    display: inline-block;
    height: 0.1rem;
    width: 75%;
    width: 100%; }
  .cart-summary__coupon-text {
    margin-top: 1rem;
    font-family: "Open-Sans", sans-serif;
    text-align: center; }
  .cart-summary__coupon-text:after {
    content: "";
    background-image: -webkit-linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
    background-image: linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
    display: inline-block;
    height: 0.1rem;
    width: 75%;
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .cart-summary__module p {
    display: flex;
    justify-content: space-between;
    margin: 1.1rem 0; }
  .cart-summary__module.shipping, .cart-summary__module.tax {
    display: none; }
  .cart-summary__module.coupon p {
    color: #dfbd5e;
    margin-top: 1rem; }
  .cart-summary__module.total p {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 1.5rem 0 6.5rem 0;
    display: flex;
    justify-content: space-between; }

.mini-cart-products {
  overflow-y: scroll;
  max-height: 340px; }

.mini-cart__title {
  text-align: center; }

.select-skin {
  width: 75%; }

.search-width {
  width: 77%; }
  @media (max-width: 75em) {
    .search-width {
      width: 75.5%; } }
  @media (max-width: 75em) {
    .search-width {
      width: 68%; } }
  @media (max-width: 37.5em) {
    .search-width {
      width: 88.5%; } }
  @media (max-width: 26.25em) {
    .search-width {
      width: 83.5%; } }

.page-checkout .navigations .link-checkout a,
.page-checkout-login .navigations .link-login a,
.page-checkout-thankyou .navigations .link-thankyou a,
.page-cart .navigations .link-cart a {
  font-size: 2.5rem;
  opacity: 1; }

.navigations .link-thankyou a {
  cursor: default; }

.navigations .link-thankyou a:hover {
  cursor: default;
  color: #dfbd5e; }

.checkout {
  color: #F0F0F0;
  width: 50%;
  margin: 0 auto; }
  @media (max-width: 37.5em) {
    .checkout {
      width: 100%; } }
  .checkout__background {
    background-color: #222939;
    border: 1px solid #dfbd5e; }
  .checkout__background-payment {
    background-color: #222939;
    border: 1px solid #dfbd5e;
    padding: 2rem; }
  .checkout__foreground {
    background-color: #2c313e;
    padding: 1rem; }
  .checkout__title {
    text-align: center;
    margin-bottom: 1rem; }
  .checkout__scroller {
    overflow-y: scroll;
    max-height: 50rem; }
  .checkout__foreground h2 {
    display: flex;
    justify-content: space-between; }
  .checkout__down-arrow {
    color: #dfbd5e;
    align-self: flex-end;
    cursor: pointer; }
  .checkout__card {
    text-align: center;
    display: none; }
  .checkout__label-title {
    text-align: left;
    margin-left: 14rem; }
    @media (max-width: 75em) {
      .checkout__label-title {
        margin-left: 7rem; } }
    @media (max-width: 37.5em) {
      .checkout__label-title {
        margin-left: 0;
        text-align: center; } }
  .checkout__line:after {
    content: "";
    background-image: -webkit-linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
    background-image: linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
    display: inline-block;
    height: 0.1rem;
    width: 75%;
    margin-top: 6rem;
    width: 100%; }
  .checkout__list {
    display: flex;
    width: 100%;
    list-style: none;
    color: #F0F0F0;
    font-size: 1.2rem;
    margin: 1rem 1rem; }
  .checkout__list li:nth-child(1) {
    width: 48%; }
    @media (max-width: 26.25em) {
      .checkout__list li:nth-child(1) {
        width: 52%; } }
  .checkout__list li:nth-child(2) {
    width: 43%; }
    @media (max-width: 26.25em) {
      .checkout__list li:nth-child(2) {
        width: 13%; } }
  .checkout__details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    margin: 1rem 1rem;
    background-color: #2c313e; }
  .checkout__input-register {
    background-color: #2c313e;
    color: #b1b1b2;
    border: 1px solid #d1bf8b;
    margin: 0.7rem;
    width: 225px;
    height: 37px;
    padding: 0.5rem;
    margin-left: 0; }
    @media (max-width: 75em) {
      .checkout__input-register {
        width: 100%; } }
  .checkout__paragraphs {
    color: #d1bf8b; }
  .checkout__method-box {
    width: 163px;
    height: 62px;
    background-color: #222939;
    margin: 1rem 2rem 0 0;
    /*background-color:$color-grey-blue;*/
    margin-bottom: 1rem;
    opacity: 0.3;
    width: 35%;
    text-align: center; }
    @media (max-width: 75em) {
      .checkout__method-box {
        width: 100%; } }
  .checkout__method-box.braintree {
    text-align: center;
    vertical-align: middle; }
  .checkout__method-box.braintree img {
    width: 90px;
    display: inline-block;
    margin-right: 0.5rem; }
  .checkout__method-box.paypalexpress img {
    background: #fff;
    padding: 0.7rem;
    width: 170px;
    /*margin-right: 0.1rem;*/ }
  .checkout__method-box:active, .checkout__method-box.selected, .checkout__method-box:hover {
    /*background-color: $color-primary;*/
    /*cursor: pointer;*/
    opacity: 1; }
  .checkout__method-box img {
    cursor: pointer; }
  .checkout__total-items {
    margin-top: 2rem; }
  .checkout__button-submit {
    margin-top: 6rem; }
  .checkout__button-submit button {
    font-size: 1.6rem;
    padding: 1rem 10rem; }
  .checkout__button-submit button[disabled] {
    opacity: 0.5;
    cursor: default; }
  .checkout__button-submit #SubmitButtonPaypal {
    display: none; }
  .paypalexpress .checkout__button-submit #SubmitButton {
    display: none; }
  .paypalexpress .checkout__button-submit #SubmitButtonPaypal {
    display: inline-block; }
  .braintree .checkout__card {
    display: block; }
  .checkout-order-summary {
    margin-bottom: 3rem; }

.margin-section {
  margin-top: 1rem; }

.small-field {
  width: 100px;
  margin-left: 0.5rem; }
  @media (max-width: 37.5em) {
    .small-field {
      margin-left: 0;
      width: 100%; } }

@media (max-width: 37.5em) {
  #thankyou__process {
    text-align: center; } }

.thankyou__paragraph {
  text-align: center; }

.thankyou__paragraph p:nth-child(1) {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-weight: bold; }

.thankyou__paragraph p:nth-child(2) {
  font-family: "Open-Sans", sans-serif;
  margin-bottom: 3rem;
  font-size: 1.2rem; }

.thankyou__paragraph p:nth-child(3) {
  font-family: "Open-Sans", sans-serif;
  font-weight: bold;
  font-size: 1.1rem;
  margin: 3rem 0; }

.thankyou__scan {
  width: 97px; }

.thankyou__process-list {
  color: #F0F0F0;
  margin-top: 1rem; }

.thankyou__process-list li {
  margin-bottom: 0.5rem; }

.thankyou__barcode {
  display: flex;
  align-items: center; }
  @media (max-width: 37.5em) {
    .thankyou__barcode {
      justify-content: center; } }

.thankyou__title-code {
  margin-top: 1rem; }

.thankyou__scan-paragraph {
  margin-left: 1rem; }
  @media (max-width: 37.5em) {
    .thankyou__scan-paragraph {
      width: 19rem; } }

.bottom-panel {
  margin-top: 10rem; }

.maintenance {
  text-align: center;
  margin: 35rem auto;
  position: relative; }
  .maintenance__logo {
    width: 300px;
    height: auto;
    margin-top: 3rem; }
    @media (max-width: 26.25em) {
      .maintenance__logo {
        width: 100%; } }
  .maintenance__paragraph {
    font-weight: bold;
    font-size: 1.8rem;
    margin: 8rem;
    position: relative;
    z-index: 1; }
    @media (max-width: 26.25em) {
      .maintenance__paragraph {
        font-size: 1.2rem; } }
  .maintenance__sub-paragraph {
    font-weight: bold;
    font-size: 1.3rem;
    margin-bottom: 0.5rem; }
  .maintenance__interact {
    display: flex;
    justify-content: center; }

.yellow-wrapping:after {
  background: url(../images/yellow-wrapping.jpg) no-repeat left center;
  height: 90px;
  width: 2105px;
  position: absolute;
  z-index: -1;
  left: -550px;
  top: -30px;
  content: "";
  opacity: 0.4; }

.product__box {
  background-color: #222939;
  border: 1px solid #dfbd5e; }

.product__background:after {
  content: "";
  width: 3rem;
  height: 0.1rem;
  background-color: #4d4d4a;
  display: block;
  position: absolute; }

.product__inner-box {
  background-color: #2c313e;
  padding: 1rem; }

.product__title {
  display: flex;
  justify-content: space-between; }

.product__down-arrow {
  color: #dfbd5e;
  align-self: flex-end;
  cursor: pointer; }

.product__detail {
  width: 90%;
  margin: 0 auto;
  overflow-y: scroll;
  max-height: 550px; }

.product__list {
  display: flex;
  width: 100%;
  list-style: none;
  color: #F0F0F0;
  font-size: 1.2rem;
  margin: 1rem 1rem;
  padding: 2rem;
  margin: 0; }

.product__line:after {
  content: "";
  background-image: -webkit-linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
  background-image: linear-gradient(to right, transparent 0%, #dfbd5e 15%, #dfbd5e 85%, transparent 100%);
  display: inline-block;
  height: 0.1rem;
  width: 75%;
  display: block;
  margin: 0 auto;
  width: 90%;
  margin-bottom: 2rem; }

.product__list li:nth-child(1) {
  margin-left: 5rem;
  width: 42%; }
  @media (max-width: 56.25em) {
    .product__list li:nth-child(1) {
      width: 45%; } }

.product__list li:nth-child(2) {
  width: 20%; }

.product__list li:nth-child(3) {
  width: 21%; }

.product__details {
  margin: 1rem 1rem;
  padding: 2rem;
  background-color: #2c313e;
  color: #dfbd5e; }

.product__orders-info {
  display: flex;
  align-items: center; }

.product__order-name {
  width: 60%; }

.product__order-name p {
  width: 80%; }

.product__actual-price {
  width: 35%; }

.product__quantity {
  width: 20%; }
